import React, { useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { Container, Wrapper, Row, Box } from "../components/util";
import { CustomerQuote } from "../components/site";

import { SignupForm } from "../components/forms";
import Layout from "../components/layout";
import Seo from "../components/seo";

import Logo from "../images/logos/PayHero/PayHero_Full.svg";
import HeroGraphic from "../images/graphics/PayHeroGraphic.svg";
import FromFlexiTime from "../images/logos/FlexiTime/FromFlexiTime_Horiz.svg";

const FullContainer = styled(Container)`
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    overflow: unset;
  }
`;

const FormWrapper = styled(Wrapper)`
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    align-self: center;
    width: 55%;
  }
`;

const FormBox = styled(Box)`
  height: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .logo {
    height: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    max-width: 100%;

    .logo {
      height: 30px;
    }
  }
`;

const GraphicArea = styled.div`
  position: sticky;
  background-color: #f9f9f9;
  top: 0;
  right: 0;
  width: 45%;
  padding: 0 30px;
  display: flex;
  align-items: center;
  height: 100vh;

  > img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      position: relative;
      margin-top: 60px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: relative;
    width: 100%;
    padding: 30px 0 0;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    > div {
      padding: 0 30px 0;
    }
  }
`;

const RegisterPayHero = (props) => {
  useEffect(() => {
    if (typeof document !== "undefined" && typeof window !== "undefined") {
      document.documentElement.classList.remove("-stopScrollMobile");
    }
  }, [props.location.search]);

  return (
    <Layout fullPage>
      <Seo
        title="14 Day Free Trial | Create a PayHero Account"
        description="PayHero is compliant, easy to use & accurate online payroll software built by FlexiTime. Start your 14 day free trial today."
        pathname={props.location.pathname}
      />
      <FullContainer>
        <FormWrapper stackGap={50}>
          <FormBox className="-center" stackGap={50}>
            <Link to="/" className="link-floating -center">
              <img className="logo" src={Logo} alt="Online Payroll Software" />
            </Link>
            <Box size={100} stackGap={40}>
              <Box className="-textCenter">
                <h1>Start Free Trial</h1>
                <p css={{ color: "#999" }}>
                  14 Days Free · First Pay Walkthrough · No Credit Card Required
                </p>
              </Box>
              <SignupForm blueLoader center />
            </Box>
            <a
              className="link-floating -center"
              href="https://www.flexitime.works"
              target="_blank"
            >
              <img
                src={FromFlexiTime}
                alt="PayHero | A payroll product by FlexiTime"
                height="18"
              />
            </a>
          </FormBox>
        </FormWrapper>
        <GraphicArea className="secondary">
          {/* <CustomerQuote
            quote="The only software on the market currently able to accurately calculate the leave entitlements for employees with irregular working patterns, without manual intervention, is PayHero."
            name="Irene Bennetts"
            company="Admin Army"
            pic="Irene_Circle.png"
            large
            center
          /> */}
          {/* <CustomerQuote
            quote="We’re usually reluctant to endorse payroll software, simply because the cost of getting it wrong is huge. PayHero gets the detail right, at the same time as being exceptionally easy to use."
            name="Hamish Mexted"
            company="Convex Accounting"
            pic="Hamish_Circle.png"
            center
            large
          /> */}
          <CustomerQuote
            quote="It feels like we moved from a prehistoric system to something modern. It’s easy to see how everything has been calculated and if I have queries I can get solutions really easily."
            name="Jenny Dutton"
            company="Greystone Wines"
            pic="Greystone_Circle.png"
            large
            center
          />
          <img src={HeroGraphic} alt="PayHero Payroll" />
        </GraphicArea>
      </FullContainer>
    </Layout>
  );
};

export default RegisterPayHero;
